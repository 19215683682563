import { useEffect } from 'react';
import { useInjection } from '../../../app/ioc/ioc.react';
import { IWorkspacesUseCases } from '../../../features2/workspaces/domain/abstractions/IWorkspacesUseCases';
import { IWorkspacesStateRepository } from '../../../features2/workspaces/domain/abstractions/IWorkspacesStateRepository';
import { useRouter } from 'next/router';
import URLParams from 'src/enums/URLParams';

import { TYPES } from 'src/features2/workspaces/di/types';
import { TYPES as PROFILE_TYPES } from 'src/features2/profile/di/types';
import useLoginViewModel from '../login/ui/useLoginViewModel';
import { IProfileUseCases } from '../../profile/domain/ProfileUseCases';

const useAuthorizationViewModel = () => {
  const { changeWorkspace } = useInjection<IWorkspacesUseCases>(TYPES.WorkspacesUseCases);
  const { fetchWorkspaces } = useInjection<IWorkspacesStateRepository>(
    TYPES.WorkspacesStateRepository
  );
  const { fetchProfile } = useInjection<IProfileUseCases>(PROFILE_TYPES.ProfileUseCases);
  const { isAuth } = useLoginViewModel();
  const router = useRouter();

  useEffect(() => {
    const fetchDataForDirectLogin = () => {
      fetchProfile().subscribe((profile) => console.log(profile));
      fetchWorkspaces().subscribe((workspaces) => console.log(workspaces));
    };
    if (router.query[URLParams.TOKEN]) {
      localStorage.setItem('direct_token', router.query[URLParams.TOKEN] as string);

      fetchDataForDirectLogin();
    }
  }, [router.query, fetchProfile, fetchWorkspaces, changeWorkspace]);
  return { isAuth };
};

export default useAuthorizationViewModel;
