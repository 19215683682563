import { Divider, Typography, Paper, Box, Container } from '@material-ui/core';
import { Trans, useTranslation } from 'next-i18next';

import AuthenticationButton from 'src/components/UI/Buttons/AuthenticationButton';
import MobileLogo from 'icons/header/mobile-logo.svg';
import useLoginViewModel from './useLoginViewModel';
import styles from './styles.module.scss';
import { PRIVACY_LINK_URL } from 'src/utils/constants/links';

const MainComponent = () => {
  // const dispatch = useAppDispatch();
  const { t } = useTranslation('login-page');
  const { handleClick, authProviders } = useLoginViewModel();

  return (
    <div className={styles.Wrapper}>
      <div>
        <MobileLogo className={styles.logo} />
        <Paper elevation={3} className={styles.paper}>
          <Container className={styles.container} maxWidth={false}>
            <Typography component="h1" className={styles.title}>
              {t('main.title', { appName: process.env.NEXT_PUBLIC_APP_NAME })}
            </Typography>
            <Typography component="p" className={styles.description}>
              {t('main.description')}
            </Typography>
            <Box className={styles.buttonsWrapper}>
              {authProviders.map((props) => {
                return (
                  <AuthenticationButton
                    onClick={() => handleClick(props.provider)}
                    key={props.provider}
                    {...props}
                  />
                );
              })}
            </Box>
          </Container>
        </Paper>
        <Box className={styles.noticeWrapper}>
          <Typography component={'p'} className={styles.notice}>
            {t('main.notice', { appName: process.env.NEXT_PUBLIC_APP_NAME })}
            <a href="https://leader.net/terms-of-service/" target={'_blank'} rel="noreferrer">
              {' '}
              {t('common:terms')}
            </a>{' '}
            {t('common:and')}{' '}
            <a href="https://leader.net/privacy-policy/" target={'_blank'} rel="noreferrer">
              {t('common:privacy')}
            </a>
          </Typography>
        </Box>
      </div>
      <Box className={styles.footerWrapper}>
        <Divider className={styles.divider} />
        <Typography component="p" className={styles.footerDescription}>
          <Trans i18nKey={'main.footer'} t={t}>
            We’re committed to your privacy. Leader uses the information you provide to us to
            contact you about your account, and inform you of product updates. You may unsubscribe
            from these communications at any time. For more information, check out our
            <a href={PRIVACY_LINK_URL} target="_blank" rel="noreferrer">
              Privacy Policy
            </a>
            .
          </Trans>
        </Typography>
      </Box>
    </div>
  );
};
export default MainComponent;
