import { useTranslation } from 'next-i18next';

import { withStyles, WithStyles, Button } from '@material-ui/core';
import Index from '../../../features2/localization/ui/DirectedText';
interface Styles {
  button_color: string;
  //@ts-ignore
  [key: string]: any;
}
//@ts-ignore
interface IButtonProps extends WithStyles<any> {
  button_color: string;
  //@ts-ignore
  icon: any;
  provider: string;
}

const StyledButton = withStyles({
  root: {
    padding: '10px 5px',
    backgroundColor: ({ button_color }: Styles) => button_color,
    borderRadius: 6,
    width: '100%',
    maxWidth: 450,
    fontWeight: 400,
    '&:not(:first-child)': {
      marginTop: 10,
    },
    '&:hover': {
      backgroundColor: ({ button_color }: Styles) => button_color,
    },
  },
  label: {
    textTransform: 'none',
    fontSize: 13,
    color: '#fff',
    position: 'relative',
  },
  iconWrapper: {
    width: 32,
    height: 32,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    left: 0,
  },
  buttonTitle: {
    textAlign: 'left',
  },
})(
  ({
    classes: { iconWrapper, buttonTitle, ...restClasses },
    icon,
    provider,
    ...rest
  }: IButtonProps) => {
    const { t } = useTranslation('common');
    return (
      //@ts-ignore
      <Button variant={'contained'} classes={restClasses} {...rest}>
        <div className={iconWrapper}>{icon}</div>
        <span className={buttonTitle}>
          <Index>
            {t('signin')} {provider}
          </Index>
        </span>
      </Button>
    );
  }
);

export default StyledButton;
