import type { NextPage } from 'next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { useEffect } from 'react';
import Login from '../../features2/authorization/ui';
import Header from '../../components/UI/Headers/Header';
import { trackEvent } from 'src/analytics/amplitude';
import styles from './styles.module.css';
import useCustomerIOViewModel from 'src/analytics/customerIO/ui/useCustomerIOViewModel';
import useLoginViewModel from 'src/features2/authorization/login/ui/useLoginViewModel';

export async function getServerSideProps({ locale = 'en' }) {
  return {
    props: {
      ...(await serverSideTranslations(locale, [
        'common',
        'billing',
        'dialogs',
        'login-page',
        'duplicates',
      ])),
      // Will be passed to the page component as props
    },
  };
}
const LoginPage: NextPage = () => {
  const { customerIOReset } = useCustomerIOViewModel();
  const { isAuth } = useLoginViewModel();
  useEffect(() => {
    customerIOReset(); // for log-out case (placed here, since we don't have common logout logic)
    trackEvent('Login_view_screen', undefined);
  }, []);

  return (
    <>
      {isAuth && (
        <Header
          hideNotifications={true}
          hideSearch={true}
          isSidebarOpened={false}
          className={styles.header}
        />
      )}

      <Login />
    </>
  );
};

export default LoginPage;
