import { LoginLayout } from '../../../components';
import Onboarding from '../onboarding/ui';
import MainComponent from '../login/ui';

import useAuthorizationViewModel from './useAuthorizationViewModel';

const Login = () => {
  const { isAuth } = useAuthorizationViewModel();

  return <LoginLayout>{!isAuth ? <MainComponent /> : <Onboarding />}</LoginLayout>;
};
export default Login;
