import { useTranslation } from 'next-i18next';
import clsx from 'clsx';
import itemColors from '../../../../../utils/constants/itemColors';
import { useFormatDistance } from '../../../../../lib/date-fns';

import WorkspaceEntity from '../../../../../db/entities/workspace/WorkspaceEntity';
import styles from './WorkspaceSelect.module.css';
import CheckIcon from 'public/images/icons/check.svg';

export default function WorkspaceSelect({
  name,
  list,
  value,
  onChange,
  className,
  avatarClassName,
  infoClassName,
}: {
  name: string;
  list: WorkspaceEntity[];
  value: WorkspaceEntity['id'] | null;
  onChange: (nextValue: WorkspaceEntity['id']) => void;
  className?: string;
  avatarClassName?: string;
  infoClassName?: string;
}) {
  const { t } = useTranslation('common');
  const handleChangeWorkspace = (e) => {
    onChange(e.target.value);
  };
  const formatDistance = useFormatDistance();

  return (
    <div className={clsx(styles.list, className)}>
      {list.map((workspaceItem, index) => {
        const checked = workspaceItem.id === value;

        const getSecondaryText = () => {
          if (workspaceItem.lastLoginAt) {
            const distance = formatDistance(Date.now(), workspaceItem.lastLoginAt);
            return t('workspace.last_use', { time: distance });
          } else {
            return workspaceItem.members.length === 1
              ? t('workspace.personal_workspace')
              : t('workspace.collaborate_workspace');
          }
        };

        const backgroundColor = itemColors[index];

        return (
          <div className={styles.listItem} key={workspaceItem.id}>
            <input
              type="radio"
              id={name + workspaceItem.id}
              name={name}
              value={workspaceItem.id}
              checked={checked}
              onChange={handleChangeWorkspace}
              className={styles.input}
              data-hj-allow={''}
            />
            <label
              htmlFor={name + workspaceItem.id}
              className={clsx(styles.button, checked && styles.selected)}
            >
              <div className={styles.avatarWrapper}>
                {workspaceItem.thumbnail ? (
                  <img
                    className={clsx(styles.avatar, avatarClassName)}
                    src={workspaceItem.thumbnail}
                    alt={workspaceItem.name}
                  />
                ) : (
                  <div
                    style={{ backgroundColor: backgroundColor }}
                    className={clsx(styles.avatar, avatarClassName)}
                  >
                    <span>{workspaceItem.name?.charAt(0)}</span>
                  </div>
                )}
              </div>

              <div className={clsx(styles.info, infoClassName)}>
                <div className={styles.textInfo}>
                  <div className={clsx(styles.text, styles.primaryText)}>
                    {workspaceItem.name && workspaceItem.name.replace(/\s/g, '').length
                      ? workspaceItem.name
                      : t('workspace.default_name')}
                  </div>
                  <div className={clsx(styles.text, styles.secondaryText)}>
                    {getSecondaryText()}
                  </div>
                </div>
                <div className={styles.iconCheck}>
                  <CheckIcon />
                </div>
              </div>
            </label>
          </div>
        );
      })}
    </div>
  );
}
