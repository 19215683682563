import { useBehaviorSubject } from 'src/app/hooks/rx';
import { useInjection } from 'src/app/ioc/ioc.react';
import { TYPES } from '../di/types';
import ILoginUseCases from '../domain/abstractions/ILoginUseCases';
const useLoginViewModel = () => {
  const loginUseCases = useInjection<ILoginUseCases>(TYPES.LoginUseCases);

  const [isAuth] = useBehaviorSubject(loginUseCases.getUserIsAuth());
  const handleClick = (provider: string) => loginUseCases.authWith(provider);

  const authProviders = loginUseCases.getAuthProviders();

  return {
    authProviders,
    handleClick,
    isAuth,
  };
};

export default useLoginViewModel;
