import ChooseWorkspaceForm from 'src/features2/workspaces/ui/ChooseWorkspaceForm/ChooseWorkspaceForm';
import useOnboardingViewModel from './useOnboardingViewModel';
import OnboardingForm from './form';
import dynamic from 'next/dynamic';
import { LOGIN_SCREEN_NAMES } from './constants';

const DynamicMobileGetTheAppNewUser = dynamic(
  () => import('./mobileGetTheAppNewUser/MobileGetTheAppNewUser')
);
const DynamicMobileUseAppIsMember = dynamic(
  () => import('./mobileUseAppIsMember/MobileUseAppIsMember')
);
const DynamicAddTeammates = dynamic(() => import('./components/AddTeammates'));

const Onboarding = () => {
  const props = useOnboardingViewModel();

  if (!props || !props.screenToShow) return null;

  const SCREENS_MAP = {
    [LOGIN_SCREEN_NAMES.CHOOSE_WORKSPACE]: <ChooseWorkspaceForm />,
    [LOGIN_SCREEN_NAMES.MOBILE_USE_APP_IS_MEMBER]: <DynamicMobileUseAppIsMember />,
    [LOGIN_SCREEN_NAMES.MOBILE_GET_APP_NEW_USER]: <DynamicMobileGetTheAppNewUser />,
    [LOGIN_SCREEN_NAMES.ADD_TEAMMATES]: (
      <DynamicAddTeammates onSkip={props.handleSkipTeammates} onAdd={props.handleAddTeammates} />
    ),
    [LOGIN_SCREEN_NAMES.ONBOARDING]: (
      <OnboardingForm
        findCountryByCode={props.findCountryByCode}
        phoneCodes={props.phoneCodes}
        user={props.user}
        disabledSubmit={props.disabledSubmit}
        handleChange={props.handleChangeForm}
        handleSubmit={props.handleSubmitForm}
        defaultValues={props.defaultValuesForm}
      />
    ),
  };

  return SCREENS_MAP[props.screenToShow];
};
export default Onboarding;
