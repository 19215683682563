import { useRouter } from 'next/router';

import routerService from '../../../services/router/routerService';
import URLParams from '../../../enums/URLParams';

const useZapierIntegration = () => {
  const { query } = useRouter();

  const handleZapierRedirect = (workspaceId: string, idToken: string) => {
    const redirectToUrl = new URL(query[URLParams.REDIRECT_URI] as string);
    const codeParam = `${idToken}/${workspaceId}`;

    redirectToUrl.searchParams.append(URLParams.STATE, query[URLParams.STATE] as string);
    redirectToUrl.searchParams.append(URLParams.CODE, codeParam);

    window.location.replace(redirectToUrl.toString());
  };

  return {
    handleZapierRedirect,
    isZapierRedirect: routerService.queryHasRedirectParams(query),
  };
};

export default useZapierIntegration;
