export const LOGIN_SCREEN_NAMES = {
  CHOOSE_WORKSPACE: 'CHOOSE_WORKSPACE',
  MOBILE_USE_APP_IS_MEMBER: 'MOBILE_USE_APP_IS_MEMBER',
  MOBILE_GET_APP_NEW_USER: 'MOBILE_GET_APP_NEW_USER',
  ONBOARDING: 'ONBOARDING',
  ADD_TEAMMATES: 'ADD_TEAMMATES',
};

export const LOGIN_SCREENS_ORDER_TO_SHOW = [
  LOGIN_SCREEN_NAMES.CHOOSE_WORKSPACE,
  LOGIN_SCREEN_NAMES.MOBILE_USE_APP_IS_MEMBER,
  LOGIN_SCREEN_NAMES.MOBILE_GET_APP_NEW_USER,
  LOGIN_SCREEN_NAMES.ONBOARDING,
  LOGIN_SCREEN_NAMES.ADD_TEAMMATES,
];
