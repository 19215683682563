import { useTranslation } from 'next-i18next';
import clsx from 'clsx';

import Button from '../../../../components/UI/Buttons/BaseButton';
import WorkspaceSelect from '../components/WorkspaceSelect/WorkspaceSelect';
import Spinner from 'components/UI/Spinner';
import ExternalLinks from 'src/enums/ExternalLinks';

import useChooseWorkspaceFormViewModel from './useChooseWorkspaceFormViewModel';

import QuestionMarkIcon from 'public/images/icons/question-mark.svg';

import styles from './ChooseWorkspaceForm.module.scss';

export default function ChooseWorkspace() {
  const { t } = useTranslation('login-page');

  const { workspaces, current, selectWorkspace, submit, fetchFailed, retry } =
    useChooseWorkspaceFormViewModel();
  const isDialogVisible = workspaces.length > 1;

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    submit();
  };

  return (
    <section className={clsx(styles.Wrapper, !isDialogVisible && styles.LoaderWrapper)}>
      {isDialogVisible ? (
        <>
          {/* TODO: use logo, need proper svg without transforms to become scalable */}
          <img className={styles.logo} src="/images/logo.svg" alt="logo" />
          <div className={styles.paper}>
            <h2 className={styles.title}>{t('choose_workspace.title')}</h2>
            {fetchFailed ? (
              <>
                <p>{t('common:error.syncFailed')}</p>
                <Button onClick={retry} variant="contained" textSize="small">
                  {t('common:action.retry')}
                </Button>
              </>
            ) : (
              <form className={styles.form} onSubmit={handleSubmit}>
                <WorkspaceSelect
                  name="workspace"
                  list={workspaces}
                  value={current}
                  onChange={selectWorkspace}
                  className={styles.select}
                  infoClassName={styles.itemInfo}
                />
                <div className={styles.formActions}>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={ExternalLinks.FAQ_WHAT_IS_WORKSPACE}
                    className={styles.helpLink}
                  >
                    <QuestionMarkIcon />
                  </a>
                  <Button type="submit" className={styles.submit} disabled={!current}>
                    {t('duplicates:Continue_action')}
                  </Button>
                </div>
              </form>
            )}
          </div>
        </>
      ) : (
        <Spinner />
      )}
    </section>
  );
}
