import { useCurrentWorkspace } from 'src/features2/workspaces/ui/hooks/useCurrentWorkspace';
import { isMobileOnly } from 'react-device-detect';

import { useCurrentUser } from 'src/features2/user/ui/hooks';
import { getDataFromLocalStorage } from 'src/utils/helpers';
import { useRouter } from 'next/router';
import { forwardToCallsPage } from 'src/services/router/appRouterService';
import routes from 'src/utils/constants/appRoutes';
import { getList, findFlagByDialCode, findFlag } from 'country-list-with-dial-code-and-flag';
import { useInjection } from 'src/app/ioc/ioc.react';
import IOnboardingUseCases from 'src/features2/authorization/onboarding/domain/abstractions/IOnboardingUseCases';
import { TYPES } from 'src/features2/authorization/onboarding/di/types';
import { useEffect, useState } from 'react';
import { IProfileUseCases } from 'src/features2/profile/domain/ProfileUseCases';
import { TYPES as PROFILE_TYPES } from 'src/features2/profile/di/types';
import { LOGIN_SCREEN_NAMES, LOGIN_SCREENS_ORDER_TO_SHOW } from './constants';
import { useCurrentSubscription } from 'src/features2/billing/ui/hooks';

const useOnboardingViewModel = () => {
  const onboardingUseCases = useInjection<IOnboardingUseCases>(TYPES.OnboardingUseCases);
  const profileUseCases = useInjection<IProfileUseCases>(PROFILE_TYPES.ProfileUseCases);
  const phoneCodes = getList();
  const [screenToShow, setScreenToShow] = useState(null);
  const { currentUser } = useCurrentUser();
  const { currentWorkspace } = useCurrentWorkspace();
  const { subscription } = useCurrentSubscription();
  const [defaultValuesForm, setDefaultValuesForm] = useState({
    companyName: currentWorkspace?.name || '',
    companySize: currentWorkspace?.companyWebsite || '',
    phoneCode: currentUser?.settings?.country || '',
    phoneNumber: '',
    companyWebsite: currentWorkspace?.companyWebsite || '',
  });
  const [disabledSubmit, setDisabledSubmit] = useState(false);
  const router = useRouter();
  // const directToken = getDirectTokenLocalStorage();
  const handleChangeForm = (data) => {
    const requiredFields = ['companyName', 'companySize', 'phoneCode', 'phoneNumber'];
    const isAllRequiredFieldsFilled = requiredFields.some((field) => {
      if (data[field] !== undefined) {
        return data[field] === '';
      }
      return false;
    });
    setDisabledSubmit(isAllRequiredFieldsFilled);
    onboardingUseCases.setOnboardingInfoTemporary(data);
  };

  const handleSkipTeammates = () => {
    profileUseCases.changeProfileSettings({ skipInviteTeammates: true });
  };
  const handleAddTeammates = () => {
    profileUseCases.changeProfileSettings({ skipInviteTeammates: true });
  };
  const handleSubmitForm = (data) => {
    onboardingUseCases.updateOnboardingInfo(data);
    onboardingUseCases.removeOnboardingInfoTemporary();
  };
  useEffect(() => {
    const onboardingInfoTemporary = getDataFromLocalStorage('onboarding_info_temporary');
    setDefaultValuesForm({
      companyName: (onboardingInfoTemporary?.companyName || currentWorkspace?.name) ?? '',
      companySize: (onboardingInfoTemporary?.companySize || currentWorkspace?.companySize) ?? '',
      companyWebsite:
        (onboardingInfoTemporary?.companyWebsite || currentWorkspace?.companyWebsite) ?? '',
      phoneCode:
        (onboardingInfoTemporary?.phoneCode ||
          currentWorkspace?.businessPhone?.substring(0, 3) ||
          (currentUser?.settings.country && findFlag(currentUser?.settings.country).dial_code)) ??
        findFlag('US').dial_code,
      phoneNumber:
        (onboardingInfoTemporary?.phoneNumber || currentWorkspace?.businessPhone?.substring(3)) ??
        '',
    });
  }, [currentWorkspace, currentUser]);

  const {
    isInvitedUser,
    isOnboardingFilled,
    hasInvitedTeammates,
    settings: { skipInviteTeammates },
    hasApp,
  } = currentUser || { settings: {} };
  const isOwner = !isInvitedUser;

  useEffect(() => {
    const screenRenderStatusMap = {
      [LOGIN_SCREEN_NAMES.CHOOSE_WORKSPACE]: !currentWorkspace,
      [LOGIN_SCREEN_NAMES.MOBILE_USE_APP_IS_MEMBER]: isMobileOnly && isInvitedUser,
      [LOGIN_SCREEN_NAMES.MOBILE_GET_APP_NEW_USER]: isMobileOnly && isOwner && !hasApp,
      [LOGIN_SCREEN_NAMES.ONBOARDING]: !isMobileOnly && isOwner && !isOnboardingFilled,
      [LOGIN_SCREEN_NAMES.ADD_TEAMMATES]:
        !isMobileOnly && isOwner && !hasInvitedTeammates && !skipInviteTeammates,
    };

    const screenToShow = LOGIN_SCREENS_ORDER_TO_SHOW.find(
      (screenName) => screenRenderStatusMap[screenName]
    );
    setScreenToShow(screenToShow);
    const checkShouldNavigateToTheApp = !screenToShow;

    if (checkShouldNavigateToTheApp) {
      if (isMobileOnly) {
        if (subscription.isUserOnFreeTrial) {
          router.push(routes.PLANS_PAGE); // SYNC-1223: navigate to plans page on mobile for better UX
        } else {
          router.push(routes.ROOT_SETTINGS_PAGE);
        }
      } else {
        forwardToCallsPage();
      }
    }
  }, [
    currentWorkspace,
    hasApp,
    isInvitedUser,
    isOnboardingFilled,
    hasInvitedTeammates,
    skipInviteTeammates,
    isOwner,
  ]);

  return {
    disabledSubmit,
    defaultValuesForm,
    currentWorkspace,
    user: currentUser,
    handleChangeForm,
    handleSubmitForm,
    phoneCodes,
    isMobileOnly,
    handleAddTeammates,
    findCountryByCode: (code) => findFlagByDialCode(code),
    isInvitedUser: currentUser?.isInvitedUser,
    userHasApp: currentUser?.hasApp,
    allFieldsFilled: currentUser?.isOnboardingFilled,
    handleSkipTeammates,
    screenToShow,
  };
};
export default useOnboardingViewModel;
