import { useEffect, useState } from 'react';
import { map, catchError, throwError } from 'rxjs';
import { useSubscription, useObservable } from '../../../../app/hooks/rx';
import { getUserLocalStorage } from 'src/utils';

import { useInjection } from '../../../../app/ioc/ioc.react';
import { IWorkspacesUseCases } from '../../domain/abstractions/IWorkspacesUseCases';
// import { IWorkspacesStateRepository } from '../../domain/abstractions/IWorkspacesStateRepository';
import { TYPES } from '../../di/types';
import useZapierIntegration from 'src/app/hooks/integrations/useZapierIntegration';
import UIWorkspace from '../entites/UIWorkspace';
import useLogout from 'src/features/authorization/hooks/useLogout';

export default function useChooseWorkspaceFormViewModel() {
  const { isZapierRedirect, handleZapierRedirect } = useZapierIntegration();
  const [workspaces, setWorkspaces] = useState<UIWorkspace[]>([]);
  const [fetchFailed, setFetchFailed] = useState<boolean>(false);
  const [current, setCurrent] = useState<string | undefined>();
  const useCases = useInjection<IWorkspacesUseCases>(TYPES.WorkspacesUseCases);
  // const repository = useInjection<IWorkspacesStateRepository>(TYPES.WorkspacesStateRepository);
  const logout = useLogout();
  // trigger to fetch workspaces
  // const fetchWorkspaces = () => repository.fetchWorkspaces();
  // const workspacesObservable = useObservable(() => {
  //   return fetchWorkspaces();
  // }, []);
  // useSubscription(workspacesObservable, () => {});

  // get workspaces from bd

  const getWorkspaces = useObservable(() => {
    return useCases.getWorkspaces().pipe(
      map((items) => {
        return items.map((workspaceItem) => new UIWorkspace(workspaceItem));
      }),
      catchError((err) => {
        setFetchFailed(true);

        return throwError(err);
      })
    );
  }, []);
  useSubscription(getWorkspaces, (items) => {
    setWorkspaces(items);
  });

  const retry = () => {
    // setFetchFailed(false);
    // fetchWorkspaces()
    //   .pipe(first())
    //   .subscribe((items) => {
    //     setWorkspaces(items);
    //   })
    //   .unsubscribe();
  };

  useEffect(() => {
    const data = getUserLocalStorage();
    if (workspaces.length === 1) {
      if (isZapierRedirect && data?.idToken) {
        handleZapierRedirect(workspaces[0].id, data.idToken);
        logout();
      } else {
        useCases.changeWorkspace(workspaces[0].id);
      }
    }
  }, [workspaces, useCases, isZapierRedirect, handleZapierRedirect, logout]);

  return {
    workspaces,
    current,
    selectWorkspace: (next: string) => {
      setCurrent(next);
    },
    submit: () => {
      const data = getUserLocalStorage();
      if (isZapierRedirect && data?.idToken) {
        handleZapierRedirect(current, data.idToken);
        logout();
      } else {
        useCases.changeWorkspace(current);
      }
    },
    fetchFailed,
    retry,
  };
}
